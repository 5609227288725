<template>
  <div class="start-window">
    <template v-if="!sent">
      <div class="b21">{{ $t('AUTH_CHANGE_NEW_PASS') }}</div>
      <form action>
        <label for="t2" class="password">
          <span>{{ $t('AUTH_CHANGE_PASSWORD') }}</span>
          <input
            type="password"
            class="inp-st"
            id="t2"
            placeholder
            @change="passwordChange"
            @input="passwordChange"
            v-model="password"
            tabindex="1"
          />
        </label>
        <label for="t2" class="password">
          <span>{{ $t('AUTH_CHANGE_CONFIRM') }}</span>
          <input
            type="password"
            class="inp-st"
            id="t2"
            placeholder
            @change="passwordChange"
            @input="passwordChange"
            v-model="confirmPass"
            tabindex="2"
          />
          <p class="login-alerts" v-if="passwordMessage">
            {{ passwordMessage }}
          </p>
        </label>

        <button class="btn-st-bg" @click.prevent.stop="changePass">
          {{ $t('AUTH_CHANGE_ENTER') }}
        </button>
      </form>
    </template>
    <template v-if="sent">
      <p>{{ $t('AUTH_SIGNUP_SENT') }}</p>
      <router-link to="/auth/login">{{ $t('AUTH_CHANGE_SIGN_IN') }}</router-link>
    </template>
  </div>
</template>
<script>
import { AUTH_CHANGEPASS } from "@/store/actions/auth";
import { mapGetters } from "vuex";
import pageUtils from "../../models/page-utils";
export default {
  name: "Login",
  props: ["code"],
  computed: {
    ...mapGetters(["authStatus"]),
    isValidPassword() {
      return pageUtils.isValidPassword(this.password);
    },
  },
  data() {
    return {
      password: "",
      confirmPass: "",
      passwordMessage: "",
      sent: false,
      isRequesting: false,
    };
  },
  methods: {
    passwordChange(event) {
      this.emailMessage = "";
      this.passwordMessage = "";
      if (this.password.length == 0) {
        this.passwordMessage = "";
        return false;
      } else {
        const result = this.isValidPassword;
        const samePasswords = this.password === this.confirmPass;

        if (!result) {
          this.passwordMessage = $t('AUTH_COMMON_VALID_PASSWORD');
          return true;
        } else {
          if (!samePasswords) {
            this.passwordMessage = $t('AUTH_SIGNUP_NOT_EQUAL');
            return true;
          }
          this.passwordMessage = "";
          return false;
        }
      }
    },
    async changePass() {
      const isEqual = this.password === this.confirmPass;
      const isValid = this.isValidPassword;
      if (isEqual && isValid && !this.isRequesting) {
        try {
          this.isRequesting = true;
          await this.$store.dispatch(AUTH_CHANGEPASS, {
            forgotCode: this.code,
            newPass: this.password,
          });
          this.sent = true;
          setTimeout(() => {
            this.$router.push("/auth/login");
          }, 5000);
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>
